<template>
  <div class="projects" id="projects">
    <div class="__block-title projects_title">
      PROJECTS
    </div>
      <transition-group
        v-if="isShowBlocks"
        class="projects-container"
        tag="div"
        appear
        name="fade"
        @before-leave="beforeLeave"
        @after-leave="afterLeave"
        @after-enter="afterAnimation"
      >
      <div class="projects__project"
           @click="openVideo(project)"
           v-for="(project, i) in projects"
           :key="project.id"
            :style="{animationDelay: animationDelay(i)}">
        <div
          class="projects__project_video-preview"
          :style="{
        backgroundImage: `url(${require(`../../assets/img/projects/previews/${project.img}.png`)})`
        }"
        >
          <div class="projects__project_play-icon __play-icon"/>
        </div>
        <div class="projects__project_desc">
          {{ project.desc }}
        </div>
        <div class="projects__project_hover"
             :style="{backgroundImage: `url(${require(`../../assets/img/projects/hovers/${project.hoverImage}.png`)})`}"
        />
      </div>
      </transition-group>
    <div
      class="projects_show-more"
      :class="{'projects_show-more--show': isMore}"
      @click="showMoreFunction"
    >
      <div class="projects_show-more_btn">
        <img src="../../assets/img/projects/more.svg" alt="show more">
      </div>
      <div class="projects_show-more_desc">
        <span v-if="!isMore">Show more</span>
        <span v-else>Show less</span>
      </div>
    </div>
    <common-fullscreen
      :description="currentIframe.desc"
      v-model:activeFullscreen="activeFullscreen"
      :iframe-link="currentIframe.iframeLink"
    />
    <div class="projects_decoration">
      <img :src="require(`../../assets/img/projects/decoration${isMobile ? '_mobile' : ''}.png`)" alt="decoration">
    </div>
  </div>
</template>

<script>
import { reactive, computed, ref, onMounted } from 'vue'
import CommonFullscreen from '@/components/common/CommonFullscreen'
import { useStore } from 'vuex'

export default {
  name: 'ProjectsBlock',
  components: { CommonFullscreen },
  setup () {
    const maxElems = 9
    const isShowBlocks = ref(false)
    const isMore = ref(false)
    const activeFullscreen = ref(false)
    const currentIframe = ref('')
    const isMoreLeave = ref(false)
    const isAfterEnter = ref(false)
    const store = useStore()
    const isMobile = computed(() => store.state.isMobile)

    const beforeLeave = () => {
      isMoreLeave.value = true
    }
    const afterLeave = () => {
      isMoreLeave.value = false
    }

    const afterAnimation = (el) => {
      isAfterEnter.value = true
      el.style.opacity = 1
    }

    const animationDelay = (i) => {
      const isMoreActive = i >= maxElems
      const moreEnterDelay = i - maxElems
      const enterActiveDelay = isMoreActive ? moreEnterDelay : i
      return `${(enterActiveDelay) * 0.4}s`
    }

    const handleScroll = () => {
      const projectsElem = document.getElementById('projects')
      if (!projectsElem) return
      const intersected = (window.scrollY + window.innerHeight) > projectsElem.offsetTop
      if (intersected) isShowBlocks.value = true
    }

    onMounted(() => {
      window.addEventListener('scroll', handleScroll)
    })

    const showMoreFunction = () => {
      isMore.value = !isMore.value
    }
    const openVideo = (project) => {
      activeFullscreen.value = true
      currentIframe.value = project
    }

    const projects = reactive([
      {
        id: 'project1',
        img: 'preview_1',
        desc: 'Collaboration project Fortnite x Twitch in the US',
        iframeLink: 'https://www.youtube.com/embed/z4q14feiatY',
        hoverImage: 'hover_1'
      },
      {
        id: 'project2',
        img: 'preview_2',
        desc: 'API based project for WARFACE',
        iframeLink: 'https://www.youtube.com/embed/YyY3uQ_WqsI',
        hoverImage: 'hover_2'
      },
      {
        id: 'project3',
        img: 'preview_3',
        desc: 'Fortnite influencers tournament',
        iframeLink: 'https://www.youtube.com/embed/4H_XyPtNNc4',
        hoverImage: 'hover_1'
      },
      {
        id: 'project4',
        img: 'preview_4',
        desc: 'Geolocation project for WARFACE',
        iframeLink: 'https://www.youtube.com/embed/dSbAOwv4qEE',
        hoverImage: 'hover_4'
      },
      {
        id: 'project5',
        img: 'preview_5',
        desc: 'Stickers for Fortnite',
        iframeLink: 'https://www.youtube.com/embed/VuqJrdZaCC4',
        hoverImage: 'hover_1'
      },
      {
        id: 'project6',
        img: 'preview_6',
        desc: 'Interactive extension on Twitch for Rick and Morty series',
        iframeLink: 'https://www.youtube.com/embed/_4cpjCWkvhE',
        hoverImage: 'hover_2'
      },
      {
        id: 'project7',
        img: 'preview_7',
        desc: 'PR-project for ANTHEM',
        iframeLink: 'https://www.youtube.com/embed/G0Z4T2-fYWc',
        hoverImage: 'hover_1'
      },
      {
        id: 'project8',
        img: 'preview_8',
        desc: 'API-based project for Fortnite in VKontakte',
        iframeLink: 'https://www.youtube.com/embed/AXvsFST5P4U',
        hoverImage: 'hover_1'
      },
      {
        id: 'project9',
        img: 'preview_9',
        desc: 'Social engagement system for Fortnite players',
        iframeLink: 'https://www.youtube.com/embed/HLPkBaQE5uc',
        hoverImage: 'hover_2'
      },
      {
        id: 'project10',
        img: 'preview_10',
        desc: 'PR-project for FIFA 17',
        iframeLink: 'https://www.youtube.com/embed/5N8sREA5oOY',
        hoverImage: 'hover_1'
      },
      {
        id: 'project11',
        img: 'preview_11',
        desc: 'PR-project for Battlefiled 1',
        iframeLink: 'https://www.youtube.com/embed/ISH71HVAyAw',
        hoverImage: 'hover_4'
      },
      {
        id: 'project12',
        img: 'preview_12',
        desc: 'API-based project for World of Warcraft',
        iframeLink: 'https://www.youtube.com/embed/L-hsj6PG_4s',
        hoverImage: 'hover_1'
      },
      {
        id: 'project13',
        img: 'preview_13',
        desc: 'Fortnite Discord Quest Italia',
        iframeLink: 'https://www.youtube.com/embed/-32noZQXGag',
        hoverImage: 'hover_2'
      },
      {
        id: 'project14',
        img: 'preview_14',
        desc: 'PR-project for National Geographic Channel',
        iframeLink: 'https://www.youtube.com/embed/lTaxnHoCys4',
        hoverImage: 'hover_1'
      },
      {
        id: 'project15',
        img: 'preview_15',
        desc: 'Interactive FB post for WARFACE community',
        iframeLink: 'https://www.youtube.com/embed/bvC7LbFro1w',
        hoverImage: 'hover_4'
      },
      {
        id: 'project16',
        img: 'preview_16',
        desc: 'Promotion of the Atomic Heart game in VK',
        iframeLink: 'https://www.youtube.com/embed/nHXuqpfsVPg?si=StuAgIrXA6LRYo3p',
        hoverImage: 'hover_1'
      },
      {
        id: 'project17',
        img: 'preview_17',
        desc: 'Ballet in Storefronts for a WINK original series',
        iframeLink: 'https://www.youtube.com/embed/vDwxuRJZVws?si=57ggQNRpmjsBgeSG',
        hoverImage: 'hover_2'
      },
      {
        id: 'project18',
        img: 'preview_18',
        desc: 'Outdoor runway fashion show for Cruella movie',
        iframeLink: 'https://www.youtube.com/embed/hjXczPCSOY0?si=HJ5A3lyf6hP0kV4m',
        hoverImage: 'hover_4'
      },
      {
        id: 'project19',
        img: 'preview_19',
        desc: 'Aviation show for Captain Marvel release',
        iframeLink: 'https://www.youtube.com/embed/730iDuTDX08?si=hkaTPAorlhx5PSC6',
        hoverImage: 'hover_2'
      },
      {
        id: 'project20',
        img: 'preview_20',
        desc: 'Promotional project for the Black Widow movie',
        iframeLink: 'https://www.youtube.com/embed/cjNrqVOvpk8?si=Q3Dn05cr4fgNgrho',
        hoverImage: 'hover_1'
      },
      {
        id: 'project21',
        img: 'preview_21',
        desc: 'Insane SMM for Tornado Energy',
        iframeLink: 'https://www.youtube.com/embed/wpcNtAfzYjg?si=kAhfCo7yY5fFKwF4',
        hoverImage: 'hover_4'
      },
      {
        id: 'project22',
        img: 'preview_22',
        desc: 'VK special project for Tornado Energy x Venom',
        iframeLink: 'https://www.youtube.com/embed/fmlp7fKfOP8?si=45Hg-ZTZSRNxBQlk',
        hoverImage: 'hover_1'
      },
      {
        id: 'project23',
        img: 'preview_23',
        desc: 'Ice performance for the Frozen 2 release',
        iframeLink: 'https://www.youtube.com/embed/CL56qtd1ICI?si=sY6WYxJ65yw2IsP0',
        hoverImage: 'hover_2'
      },
      {
        id: 'project24',
        img: 'preview_24',
        desc: 'Blogger project for the Disney Princess franchise',
        iframeLink: 'https://www.youtube.com/embed/wLwABKoHypk?si=XCQsVBpck1_30LQH',
        hoverImage: 'hover_4'
      },
      {
        id: 'project25',
        img: 'preview_25',
        desc: 'Video with FC Spartak Moscow players for the FIFA 20 game',
        iframeLink: 'https://www.youtube.com/embed/l6hsO2lc54A?si=_N1VE49gXM4T7n_w',
        hoverImage: 'hover_1'
      }
    ])

    const sliceProjects = computed(() => isMore.value ? projects : projects.slice(0, maxElems))

    return {
      projects: sliceProjects,
      showMoreFunction,
      isMore,
      activeFullscreen,
      openVideo,
      currentIframe,
      maxElems,
      afterAnimation,
      isShowBlocks,
      animationDelay,
      beforeLeave,
      afterLeave,
      isMobile
    }
  }
}
</script>

<style lang="scss" scoped>
.projects {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include desktop {
    margin-top: 350px;
  }

  &-container {
    display: flex;
    justify-content: center;
    column-gap: 24px;
    flex-wrap: wrap;
    row-gap: 50px;
    margin-top: 71px;
    width: $desktop;

    @include mobile {
      display: flex;
      grid-gap: 0;
      flex-direction: column;
      align-items: center;
      width: 300px;
      padding: 0 10px;
      margin-top: 40px;
    }
  }
  &_title {
    margin-top: 140px;

    @include mobile {
      margin-top: 120px;
    }
  }

  &__project {
    max-width: 400px;
    cursor: pointer;
    position: relative;
    transition: all .3s;
    transform: translateY(0);
    opacity: 0;

    &:not(:first-child) {
      @include mobile {
        margin-top: 40px;
      }
    }

    &_video-preview {
      width: 400px;
      height: 224px;
      position: relative;
      background-size: 100%;

      @include mobile {
        width: 300px;
        height: 168px;
      }
    }

    &_play-icon {
      transition: all .3s;
    }

    &_desc {
      margin-top: 20px;
      font-size: 18px;
      line-height: 100%;
      text-align: center;
      font-weight: 400;

      @include mobile {
        margin-top: 15px;
        font-size: 12px;
      }
    }

    &_hover {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height:100%;
      background-repeat: no-repeat;
      background-size: 100%;
      transition: opacity .3s;
      opacity: 0;

      @include mobile {
        display: none;
      }
    }

    @include desktop {
      &:hover {
        .projects__project_play-icon {
          transform: scale(1.2) translate(-50%, -50%);
        }
        .projects__project_hover {
          opacity: 1;
        }
      }
    }
  }

  &_show-more {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    cursor: pointer;

    @include desktop {
      &:hover {
        .projects_show-more_btn {
          background: #FF0000;
        }
      }
    }

    &_btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: #000;
      transition: all .3s;

      img {
        padding-top: 2px;
        width: 16px;
      }
    }

    &_desc {
      margin-top: 18px;
      font-size: 18px;
      line-height: 120%;
    }

    &--show {
      .projects_show-more_btn {
        transform: rotate(180deg);
      }
    }
  }

  &_decoration {
    position: absolute;
    z-index: -1;
    top: -150px;
    width: 100%;

    @include mobile {
      position: static;
      width: 100%;
    }
  }
}

.fade-enter-active {
  animation: fade-in 1s forwards;
}
.fade-leave-active {
  animation: fade-in .5s reverse;
  animation-delay: 0s !important;
}

@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateY(100px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
