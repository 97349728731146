<template>
  <div class="socials" :class="{'socials--column': column}">
    <a class="socials_social"
       v-for="(icon, i) in icons"
       :href="icon.link"
       target="_blank"
       :key="i"
       :class="[`socials_social--${icon.value}`]">
    </a>
  </div>
</template>

<script>
export default {
  name: 'CommonSocials',
  props: {
    column: {
      type: Boolean,
      default: false
    }
  },
  setup () {
    const icons = [
      {
        value: 'fb',
        link: 'https://www.facebook.com/wnm.digital'
      },
      {
        value: 'mail',
        link: 'mailto:info@wnm.digital?subject=Amazing request from best client in the world'
      },
      {
        value: 'in',
        link: 'https://www.linkedin.com/company/2976587'
      }
    ]

    return {
      icons
    }
  }
}
</script>

<style scoped lang="scss">
.socials {
  display: flex;
  align-items: center;

  @include desktop {
    column-gap: 10px;
  }

  &_social {
    width: 30px;
    height: 30px;
    cursor: pointer;

    background-size: 100%;

    &--mail {
      background-image: url("../../assets/img/common/socials/mail.png");
    }

    &--fb {
      background-image: url("../../assets/img/common/socials/fb.png");
    }

    &--in {
      background-image: url("../../assets/img/common/socials/in.png");
    }

    @include desktop {
      &:hover {

        &.socials_social--mail {
          background-image: url("../../assets/img/common/socials/mail_hovered.png");
        }

        &.socials_social--fb {
          background-image: url("../../assets/img/common/socials/fb_hovered.png");
        }

        &.socials_social--in {
          background-image: url("../../assets/img/common/socials/in_hovered.png");
        }
      }
    }
  }

  &--column {
    flex-direction: column;
    align-items: flex-start;
  }
}
</style>
