<template>
  <div class="layout">
    <header-site/>
    <preview-block/>
    <clients-block/>
    <numbers-block/>
    <projects-block/>
    <also-block/>
    <lets-talk-block/>
    <footer-block/>
  </div>
</template>

<script>
import HeaderSite from '@/components/blocks/HeaderBlock'
import PreviewBlock from '@/components/blocks/PreviewBlock'
import ClientsBlock from '@/components/blocks/ClientsBlock'
import NumbersBlock from '@/components/blocks/NumbersBlock'
import ProjectsBlock from '@/components/blocks/ProjectsBlock'
import AlsoBlock from '@/components/blocks/AlsoBlock'
import LetsTalkBlock from '@/components/blocks/LetsTalkBlock'
import FooterBlock from '@/components/blocks/FooterBlock'
import { useStore } from 'vuex'

export default {
  name: 'App',
  components: {
    FooterBlock,
    LetsTalkBlock,
    AlsoBlock,
    ProjectsBlock,
    NumbersBlock,
    ClientsBlock,
    PreviewBlock,
    HeaderSite
  },
  setup () {
    const store = useStore()
    const mobileWidth = 1279
    if (window.innerWidth < mobileWidth) store.commit('SET_MOBILE', true)
    window.addEventListener('resize', () => {
      if (window.innerWidth < mobileWidth) store.commit('SET_MOBILE', true)
      else store.commit('SET_MOBILE', false)
    })
    return {
      store
    }
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@700&family=Montserrat:wght@400;500;600;700&display=swap');

body {
  padding: 0;
  margin: 0;
}

.layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-width: 1280px;
  overflow-x: hidden;
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @include mobile {
    min-width: 100%;
  }

  img {
    max-width: 100%;
  }
}

.__block-title {
  font-family: Comfortaa;
  font-size: 36px;
  font-weight: 700;
  line-height: 130%;
  text-align: center;
  letter-spacing: 0.5em;
  text-transform: uppercase;

  @include mobile {
    font-size: 18px;
  }
}

.__play-icon {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 42px;
  height: 42px;
  background: #FF0000;
  border-radius: 50%;
  z-index: 20;
  transform-origin: top left;

  &::before {
    content: '';
    position: absolute;
    left: 32%;
    width: 20px;
    height: 20px;
    background: url("./assets/img/icons/play-icon.svg") no-repeat center center / contain;
  }
}
</style>
