<template>
  <div class="clients" id="clients">
    <div class="__block-title clients_title">
      CLIENTS
    </div>
    <div class="clients-container">
      <div
        class="clients__client"
        :class="[`clients__client--${num}`]"
        v-for="num in 15"
        :key="num">
        <img :src="require(`../../assets/img/clients/client_${num}.png`)" alt="client">
      </div>
    </div>
    <div class="clients_abstraction">
      <img src="../../assets/img/clients/clients_abstraction.png" alt="abstraction">
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex'

export default {
  name: 'ClientsBlock',
  setup () {
    const store = useStore()
    return {
      store
    }
  }
}
</script>

<style lang="scss" scoped>
  .clients {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    &_title {
      margin-top: 200px;
      margin-bottom: 43px;

      @include mobile {
        margin-top: 100px;
        margin-bottom: 23px;
      }
    }

    &__client {
      display: flex;
      align-items: center;
      &--1 {
        width: 55px;

        @include mobile {
          width: 19px;
        }
      }
      &--2 {
        width: 128px;

        @include mobile {
          width: 53.47px;
        }
      }
      &--3 {
        width: 60px;

        @include mobile {
          width: 29.41px;
        }
      }
      &--4 {
        width: 90px;

        @include mobile {
          width: 38.57px;
        }
      }
      &--5 {
        width: 124px;

        @include mobile {
          width: 51.94px;
        }
      }
      &--6 {
        width: 66.86px;

        @include mobile {
          width: 27.88px;
        }
      }
      &--7 {
        width: 113.57px;

        @include mobile {
          width: 47.36px;
        }
      }
      &--8 {
        width: 65px;

        @include mobile {
          width: 27.5px;
        }
      }
      &--9 {
        width: 98.91px;

        @include mobile {
          width: 41.25px;
        }
      }
      &--10 {
        width: 123.64px;

        @include mobile {
          width: 51.56px;
        }
      }
      &--11 {
        width: 136.46px;

        @include mobile {
          width: 63px;
        }
      }
      &--12 {
        width: 146.54px;

        @include mobile {
          width: 61.1px;
        }
      }
      &--13 {
        width: 54.95px;

        @include mobile {
          width: 26.35px;
        }
      }
      &--14 {
        width: 152.03px;

        @include mobile {
          width: 68.36px;
        }
      }
      &--15 {
        width: 105.09px;

        @include mobile {
          width: 43.92px;
        }
      }
    }
    &-container {
      box-sizing: border-box;
      display: grid;
      justify-items: center;
      justify-content: center;
      grid-template-columns: repeat(5, max-content);
      grid-column-gap: 100px;
      grid-gap: 50px;
      background: #FCFCFC;
      width: 1248px;
      padding: 30px 193px 60px 193px;

      @include mobile {
        display: flex;
        flex-wrap: wrap;
        grid-column-gap: 30px;
        grid-row-gap: 35px;
        width: auto;
        max-width: 320px;
        padding: 32px 30px 28px 30px;
      }
    }

    &_abstraction {
      width: 100%;
      top: 530px;
      position: absolute;

      @include mobile {
        position: static;
        width: 100%;
        margin-top: -40px;
      }

      img {
        width: 100%;
      }
    }
  }
</style>
