<template>
  <header class="header">
    <div class="header_container">
      <div class="header__wnm">
        <div class="header__wnm_wnm-logo">
          <img src="../../assets/img/header/wnm_logo_header.png" alt="wnm">
        </div>
        <div class="header__wnm_wnm-text">
          <img src="../../assets/img/header/we_need_more.png" alt="wnm">
        </div>
        <div class="header__wnm_more">

        </div>
      </div>
      <div class="header__menu">
        <common-mobile-menu
          v-if="store.state.isMobile"
          v-model:active="active"
        >
          <div class="header__menu_items">
            <div class="header__menu_items_item" @click="scrollToBlock('clients')">Clients</div>
            <div class="header__menu_items_item" @click="scrollToBlock('numbers')">Numbers</div>
            <div class="header__menu_items_item" @click="scrollToBlock('projects')">Projects</div>
            <div class="header__menu_items_item" @click="scrollToBlock('also')">Also</div>
          </div>
          <common-socials column/>
        </common-mobile-menu>
        <common-socials v-else/>
      </div>
    </div>
  </header>
</template>

<script>

import CommonSocials from '@/components/common/CommonSocials'
import CommonMobileMenu from '@/components/common/CommonMobileMenu'
import { useStore } from 'vuex'
import { ref } from 'vue'

export default {
  name: 'HeaderBlock',
  components: { CommonMobileMenu, CommonSocials },
  setup () {
    const store = useStore()
    const active = ref(false)
    const scrollToBlock = (blockId) => {
      const block = document.getElementById(blockId)

      if (block) {
        // Use el.scrollIntoView() to instantly scroll to the element
        block.scrollIntoView({ behavior: 'smooth' })
      }
    }
    return {
      store,
      active,
      scrollToBlock
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  width: 100%;

  &_container {
    display: grid;
    grid-template-areas: 'wnm wnm menu'
                        'partner partner parter';
    justify-content: space-between;
    padding: 20px 30px;

    @include mobile {
      display: flex;
      padding: 16px 13px;
    }
  }

  &__wnm {
    grid-area: wnm;
    display: flex;
    align-items: center;

    @include mobile {
      flex-direction: column;
      order: 2;
      padding-right: 30px;
    }

    &_wnm-logo {
      width: 172px;
      margin-right: 17px;

      @include mobile {
        width: 72px;
        margin-right: 0;
      }
    }
    &_wnm-text {
      width: 220px;

      @include mobile {
        width: 77.58px;
      }
    }
  }

  &__menu {
    grid-area: menu;
    justify-self: end;

    &_items {
      display: flex;
      flex-direction: column;
      margin-bottom: 7px;
      font-weight: 600;
      font-size: 12px;
      line-height: 210%;
    }

    @include mobile {
      order: 3
    }
  }

  &__partner {
    grid-area: partner;
    margin-top: 34px;

    @include mobile {
      margin: 0;
      order: 1;
    }
  }
}
</style>
