<template>
  <footer class="footer">
    <div class="footer_container">
      <div class="footer__info">
        <div class="footer__info_contact">
            <span class="footer__info_contact_caption">
              For requests:
            </span>
          <a href="mailto:info@wnm.digital?subject=Amazing request from best client in the world" class="footer__info_contact_link">info@wnm.digital</a>
        </div>
        <div class="footer__info_contact">
            <span class="footer__info_contact_caption">
              For employees:
            </span>
          <a href="mailto:hr@wnm.digital" class="footer__info_contact_link">hr@wnm.digital</a>
        </div>
<!--        <div class="footer__info_contact">
          <a href="tel:+79999999" class="footer__info_contact_link">+7 999 99 99</a>
        </div>-->
      </div>
      <div class="footer__address">
        35 1-ya Brestskaya St, 125047<br>
         Moscow, Russia
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'FooterBlock'
}
</script>

<style lang="scss" scoped>
  .footer {
    margin-top: 129px;
    width: 100%;
    background: #545454;

    @include mobile {
      margin-top: 96px;
    }

    &_container {
      display: flex;
      padding: 37px 34px 42px 37px;

      @include mobile {
        flex-direction: column;
        padding: 37px 34px 33px 34px;
      }
    }

    &__info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: white;
      margin-left: 60px;

      @include mobile {
        margin-left: 0;
        margin-top: 26px;
      }

      a {
        text-decoration: none;
        color: white;
      }

      &_contact {
        font-size: 12px;
        line-height: 150%;

        @include mobile {
          font-weight: 400;
          font-size: 10px;
          line-height: 150%;
        }
        &_caption {
          font-weight: 400;
        }
        &_link {
          font-weight: 700;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    &__address {
      font-weight: 400;
      font-size: 12px;
      line-height: 130%;
      color: #FFFFFF;
      align-self: center;
      margin-left: auto;

      @include mobile {
        font-size: 10px;
        margin-left: 0;
        align-self: flex-start;
        margin-top: 10px;
      }
    }
  }
</style>
