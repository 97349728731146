<template>
  <div class="talk">
    <div class="__block-title talk_title">
      let’s talk
    </div>
    <a
      href="mailto:info@wnm.digital?subject=Amazing request from best client in the world"
      class="talk_img-link"
    />
    <div class="talk_img-start">
      <img src="../../assets/img/talk/start-arrow.png" class="talk_img-start_arrow" alt="start">
      <img src="../../assets/img/talk/start.png" class="talk_img-start_start" alt="start">
    </div>
    <common-socials class="talk_socials"/>
  </div>
</template>

<script>
import CommonSocials from '@/components/common/CommonSocials'

export default {
  name: 'LetsTalkBlock',
  components: { CommonSocials },
  setup () {
  }
}
</script>

<style lang="scss" scoped>
.talk {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  &_img-link {
    display: block;
    width: 454px;
    height: 183px;
    cursor: pointer;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url("../../assets/img/talk/work.png");

    @include desktop {
      &:hover {
        background-image: url("../../assets/img/talk/work_hover.png");
      }
    }

    @include mobile {
      width: 290px;
      height: 116px;
    }
  }

  &_img-start {
    position: absolute;
    right: -70px;
    top: 170px;
    width: 115px;
    height: 71.28px;

    &_arrow {
      width: 37px;

      @include mobile {
        width: 27px;
      }
    }

    &_start {
      width: 115px;

      @include mobile {
        width: 88px;
      }
    }

    @include mobile {
      top: 105px;
      right: 0;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      width: auto;
      height: auto;
    }
  }

  &_socials {
    margin-top: 30px;
  }
}
</style>
