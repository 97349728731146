<template>
  <div class="mobile-menu">
    <div
      class="mobile-menu_hamburger"
      @click="$emit('update:active', !active)"
      :class="{'mobile-menu_hamburger--not-active': !active, 'mobile-menu_hamburger--active': active}"
    >
      <span></span>
      <span></span>
      <span></span>
    </div>
    <transition name="move">
      <div class="mobile-menu_content" v-if="active">
        <slot></slot>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'CommonMobileMenu',
  props: {
    active: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:active']
}
</script>

<style lang="scss" scoped>

.mobile-menu {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  &_hamburger {
    width: 19px;
    height: 12px;
    cursor: pointer;

    &--active span:nth-child(1) {
      animation: ease .7s top forwards;
    }

    &--not-active span:nth-child(1) {
      animation: ease .7s top-2 forwards;
    }

    &--active span:nth-child(2) {
      animation: ease .7s scaled forwards;
    }

    &--not-active span:nth-child(2) {
      animation: ease .7s scaled-2 forwards;
    }

    &--active span:nth-child(3) {
      animation: ease .7s bottom forwards;
    }

    &--not-active span:nth-child(3) {
      animation: ease .7s bottom-2 forwards;
    }

    span {
      display: block;
      width: 19px;
      border-radius: 3px;
      height: 2px;
      background: #000;
      transition: all .3s;
      position: relative;
    }

    span + span {
      margin-top: 4px;
    }
  }

  &_content {
    position: absolute;
    z-index: 50;
    display: flex;
    flex-direction: column;
    top: 30px;
    width: 105px;
    height: 253px;
    padding: 11px 12px;
    box-sizing: border-box;
    background: #F4F4F4;
    border-radius: 4px;
  }
}

@keyframes top {
  0% {
    top: 0;
    transform: rotate(0);
  }
  50% {
    top: 7px;
    transform: rotate(0);
  }
  100% {
    top: 7px;
    transform: rotate(45deg);
  }
}

@keyframes top-2 {
  0% {
    top: 5px;
    transform: rotate(45deg);
  }
  50% {
    top: 5px;
    transform: rotate(0deg);
  }
  100% {
    top: 0;
    transform: rotate(0deg);
  }
}

@keyframes bottom {
  0% {
    bottom: 0;
    transform: rotate(0);
  }
  50% {
    bottom: 5px;
    transform: rotate(0);
  }
  100% {
    bottom: 5px;
    transform: rotate(135deg);
  }
}

@keyframes bottom-2 {
  0% {
    bottom: 5px;
    transform: rotate(135deg);
  }
  50% {
    bottom: 5px;
    transform: rotate(0);
  }
  100% {
    bottom: 0;
    transform: rotate(0);
  }
}

@keyframes scaled {
  50% {
    transform: scale(0);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes scaled-2 {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

.move-enter-active {
  animation: bounce-in .7s;
}
.move-leave-active {
  animation: bounce-in .7s reverse;
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
</style>
