<template>
  <transition name="fade">
  <div class="fullscreen" v-if="activeFullscreen">
    <div class="fullscreen_container">
      <iframe class="fullscreen__iframe"
              :src="`${iframeLink}?autoplay=1`"
              title="YouTube video player"
              frameborder="0"
              allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen></iframe>
      <div class="fullscreen__close" @click="$emit('update:activeFullscreen', false)">
        <img src="../../assets/img/icons/close.png" alt="close">
      </div>
      <div class="fullscreen__description" v-if="description">
        {{description}}
      </div>
    </div>
  </div>
  </transition>
</template>

<script>
export default {
  name: 'CommonFullscreen',
  emits: ['update:activeFullscreen'],
  props: {
    iframeLink: String,
    activeFullscreen: Boolean,
    description: String
  }
}
</script>

<style lang="scss" scoped>
.fullscreen {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(#000, 0.8);
  z-index: 1000;

  &__iframe {
    width: 100%;
    height: 100%;
    @include mobile {
    width: 90vw;
    height: 60vw;
    }
  }

  &_container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
    height: 80%;

    @include mobile {
      height: auto;
    }
  }

  &__close {
    position: absolute;
    top: -55px;
    right: 0;
    width: 50px;
    height: 50px;

    cursor: pointer;
  }

  &__description {
    position: absolute;
    bottom: -50px;
    left: 10px;
    color: white;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
  }
}

.fade-enter-active, .fade-leave-active {
  transition: .2s ease-in-out;
}

.fade-enter {
  opacity: 1;
}

.fade-enter-to, .fade-leave-to {
  opacity: 0;
}
</style>
