<template>
  <div class="also" id="also">
    <div class="__block-title also_title">
      ALSO
    </div>
    <div class="also_desc">
      We can develop <b>something special</b> to solve your tasks!
    </div>
    <div class="also_circles" id="circles">
      <div class="also_circles_circle"
           :class="{'also_circles_circle--clickable': circle.clickable}"
           v-for="circle in isMobile ? circlesMobile : circlesPC"
           :id="circle.type"
           :ref="circle.type"
           @click="circle.clickable ? openVideo(circle) : undefined"
           :style="{
              top: `${circle.y}px`,
              left: `${startX + circle.x}px`,
              zIndex: circle.zIndex,
              width: `${circle.hasOwnProperty('width') ? circle.width + 'px' : 'auto'}`
            }"
           :key="circle.type">
        <div v-if="circle.clickable" class="also_circles_circle_play-icon __play-icon"/>
        <img :src="circle.img" alt="circle">
      </div>
    </div>
    <div class="also_decoration also_decoration--1">
      <img src="../../assets/img/also/decorations/decoration_1.png" alt="decoration1">
    </div>
    <div class="also_decoration also_decoration--2">
      <img src="../../assets/img/also/decorations/decoration_2.png" alt="decoration2">
    </div>
    <div class="also_decoration also_decoration--3">
      <img src="../../assets/img/also/decorations/decoration_3.png" alt="decoration3">
    </div>
    <common-fullscreen v-model:active-fullscreen="activeFullscreen" :iframe-link="currentIframe.iframeLink"/>
  </div>
</template>

<script>
import { reactive, ref, computed, toRefs, onMounted } from 'vue'
import CommonFullscreen from '@/components/common/CommonFullscreen'
import { useStore } from 'vuex'

export default {
  name: 'AlsoBlock',
  components: { CommonFullscreen },
  setup () {
    const windowWidth = ref(window.innerWidth)
    const store = useStore()
    const isMobile = computed(() => store.state.isMobile)
    const containerWidth = ref(isMobile.value ? 320 : 1280)
    const startX = ref((windowWidth.value - containerWidth.value) / 2)

    const circles = reactive({
      circlesPC: [
        {
          type: 'circle2',
          clickable: false,
          iframeLink: '',
          img: require('../../assets/img/also/circles/circle_2.png'),
          y: 80,
          x: 220,
          zIndex: 3,
          width: 212,
          move: 'not'
        },
        {
          type: 'circle3',
          clickable: false,
          img: require('../../assets/img/also/circles/circle_3.png'),
          y: 1,
          x: 430,
          zIndex: 3,
          width: 91,
          move: 'not'
        },
        {
          type: 'circle4',
          clickable: true,
          iframeLink: 'https://www.youtube.com/embed/G0Z4T2-fYWc',
          img: require('../../assets/img/also/circles/circle_4.png'),
          y: 300,
          x: 430,
          zIndex: 3,
          width: 183,
          move: 'down'
        },
        {
          type: 'circle5',
          clickable: true,
          iframeLink: 'https://www.youtube.com/embed/z4q14feiatY',
          img: require('../../assets/img/also/circles/circle_5.png'),
          y: 460,
          x: 250,
          zIndex: 3,
          width: 169,
          move: 'not'
        },
        {
          type: 'circle6',
          clickable: false,
          iframeLink: '',
          img: require('../../assets/img/also/circles/circle_6.png'),
          y: 600,
          x: 30,
          zIndex: 8,
          width: 236,
          move: 'up'
        },
        {
          type: 'circle7',
          clickable: false,
          iframeLink: '',
          img: require('../../assets/img/also/circles/circle_7.png'),
          y: 850,
          x: 230,
          zIndex: 3,
          width: 105,
          move: 'not'
        },
        {
          type: 'circle8',
          clickable: true,
          iframeLink: 'https://www.youtube.com/embed/L-hsj6PG_4s',
          img: require('../../assets/img/also/circles/circle_8.png'),
          y: 650,
          x: 530,
          zIndex: 6,
          width: 255,
          move: 'not'
        },
        {
          type: 'circle9',
          clickable: true,
          iframeLink: 'https://www.youtube.com/embed/5N8sREA5oOY',
          img: require('../../assets/img/also/circles/circle_9.png'),
          y: 400,
          x: 640,
          zIndex: 2,
          width: 459,
          move: 'up'
        },
        {
          type: 'circle10',
          clickable: false,
          iframeLink: '',
          img: require('../../assets/img/also/circles/circle_10.png'),
          y: 200,
          x: 940,
          zIndex: 2,
          width: 180,
          move: 'not'
        },
        {
          type: 'circle11',
          clickable: true,
          iframeLink: 'https://www.youtube.com/embed/ISH71HVAyAw',
          img: require('../../assets/img/also/circles/circle_11.png'),
          y: 130,
          x: 680,
          zIndex: 6,
          width: 178,
          move: 'not'
        },
        {
          type: 'circle12',
          clickable: false,
          iframeLink: 'https://www.youtube.com/embed/jexE8vm8q8E',
          img: require('../../assets/img/also/circles/circle_12.png'),
          y: 60,
          x: 500,
          zIndex: 3,
          width: 154,
          move: 'down'
        }
      ],
      circlesMobile: [
        {
          type: 'circle2',
          clickable: false,
          iframeLink: 'https://www.youtube.com/embed/jexE8vm8q8E',
          img: require('../../assets/img/also/circles/circle_2.png'),
          y: 60,
          x: startX.value,
          zIndex: 3,
          width: 107,
          move: 'not'
        },
        {
          type: 'circle3',
          clickable: false,
          img: require('../../assets/img/also/circles/circle_3.png'),
          y: 0,
          x: 90,
          zIndex: 3,
          width: 45,
          move: 'down'
        },
        {
          type: 'circle4',
          clickable: true,
          iframeLink: 'https://www.youtube.com/embed/G0Z4T2-fYWc',
          img: require('../../assets/img/also/circles/circle_4.png'),
          y: 190,
          x: 120,
          zIndex: 8,
          width: 94.3,
          move: 'down'
        },
        {
          type: 'circle5',
          clickable: true,
          iframeLink: 'https://www.youtube.com/embed/z4q14feiatY',
          img: require('../../assets/img/also/circles/circle_5.png'),
          y: 320,
          x: 50,
          zIndex: 3,
          width: 84.5,
          move: 'not'
        },
        {
          type: 'circle6',
          clickable: false,
          iframeLink: 'https://www.youtube.com/embed/jexE8vm8q8E',
          img: require('../../assets/img/also/circles/circle_6.png'),
          y: 410,
          x: startX.value - 20,
          zIndex: 5,
          width: 87,
          move: 'up'
        },
        {
          type: 'circle7',
          clickable: false,
          iframeLink: '',
          img: require('../../assets/img/also/circles/circle_7.png'),
          y: 400,
          x: 220,
          zIndex: 3,
          width: 52.5,
          move: 'not'
        },
        {
          type: 'circle8',
          clickable: true,
          iframeLink: 'https://www.youtube.com/embed/L-hsj6PG_4s',
          img: require('../../assets/img/also/circles/circle_8.png'),
          y: 620,
          x: 60,
          zIndex: 3,
          width: 131,
          move: 'up'
        },
        {
          type: 'circle9',
          clickable: true,
          iframeLink: 'https://www.youtube.com/embed/5N8sREA5oOY',
          img: require('../../assets/img/also/circles/circle_9.png'),
          y: 470,
          x: 120,
          zIndex: 2,
          width: 247,
          move: 'not'
        },
        {
          type: 'circle10',
          clickable: false,
          iframeLink: '',
          img: require('../../assets/img/also/circles/circle_10.png'),
          y: 250,
          x: 240,
          zIndex: 2,
          width: 89.57,
          move: 'not'
        },
        {
          type: 'circle11',
          clickable: true,
          iframeLink: 'https://www.youtube.com/embed/ISH71HVAyAw',
          img: require('../../assets/img/also/circles/circle_11.png'),
          y: 110,
          x: 200,
          zIndex: 6,
          width: 96,
          move: 'not'
        },
        {
          type: 'circle12',
          clickable: false,
          iframeLink: 'https://www.youtube.com/embed/jexE8vm8q8E',
          img: require('../../assets/img/also/circles/circle_12.png'),
          y: 50,
          x: 140,
          zIndex: 7,
          width: 76.95,
          move: 'down'
        }
      ]
    })

    let previousScroll = 0
    const circlesScroll = isMobile.value ? circles.circlesMobile : circles.circlesPC
    circlesScroll.forEach(x => Object.defineProperty(x, 'speed', {
      value: isMobile.value ? 1 : 1.5
    }))

    window.addEventListener('resize', () => {
      windowWidth.value = window.innerWidth
      containerWidth.value = isMobile.value ? 320 : 1280
      startX.value = (windowWidth.value - containerWidth.value) / 2
    })

    const handleScroll = () => {
      const alsoElem = document.getElementById('also')
      // const positionAlso = alsoElem.getBoundingClientRect()
      const scrollY = window.scrollY
      const intersected = (window.scrollY + window.innerHeight) > (alsoElem.offsetTop + 300)

      if (intersected) {
        // const sortedCircles = circlesScroll.sort((a, b) => a.y - b.y)
        // const firstCircle = sortedCircles[0]
        // const lastCircle = sortedCircles[sortedCircles.length - 1]
        // const lastCircleElem = document.getElementById(lastCircle.type)
        // const positionLastCircleElem = lastCircleElem.getBoundingClientRect()

        circlesScroll.forEach(x => {
          if (scrollY < previousScroll) {
            if (x.move === 'down') {
              x.y -= x.speed
            } else if (x.move === 'up') {
              x.y += x.speed
            }
          } else {
            if (x.move === 'down') {
              x.y += x.speed
            } else if (x.move === 'up') {
              x.y -= x.speed
            }
          }
        })
      }
      previousScroll = scrollY
    }

    onMounted(() => {
      window.addEventListener('scroll', handleScroll)
    })

    const activeFullscreen = ref(false)
    const currentIframe = ref('')

    const openVideo = (circle) => {
      activeFullscreen.value = true
      currentIframe.value = circle
    }

    return {
      activeFullscreen,
      currentIframe,
      openVideo,
      isMobile,
      startX,
      ...toRefs(circles)
    }
  }
}
</script>

<style lang="scss" scoped>
  .also {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: 1500px;

    @include mobile {
      min-height: 800px;
    }

    &_title {
      margin-top: 220px;

      @include mobile {
        margin-top: 0;
      }
    }

    &_desc {
      margin-top: 11px;
      font-weight: 400;
      font-size: 18px;
      line-height: 29px;

      @include mobile {
        margin-top: 14px;
        max-width: 227px;
        text-align: center;
        font-size: 12px;
        line-height: 130%;
      }
    }

    &_circles {
      margin-top: 80px;
      min-height: 850px;
      width: 100%;
      position: relative;

      @include mobile {
        min-height: 1000px;
        margin-top: 128px;
      }
      &_circle {
        position: absolute;
        transition: transform .3s ease-in-out;
        transform: scale(1);

        &--clickable {
          cursor: pointer;

          &:hover {
            transform: scale(1.1);
          }
        }

        &_play-icon {
          @include mobile {
            width: 22px;
            height: 22px;

            &::before {
              width: 10px;
              height: 10px;
            }
          }
        }
      }
    }

    &_decoration {
      position: absolute;
      &--1 {
        top: 250px;
        right: 0;

        @include mobile {
          top: 60px;
          width: 165px;
        }
      }
      &--2 {
        top: 720px;
        left: 0;

        @include mobile {
          display: none;
        }
      }
      &--3 {
        bottom: -120px;
        right: 0;

        @include mobile {
          width: 200px;
          bottom: 50px;
        }
      }
    }
  }
</style>
