<template>
  <div class="preview">
    <div class="preview_icons">
      <div
        class="preview_icons_icon"
        :class="{'preview_icons_icon--black': icon.type === 'black', 'preview_icons_icon--red': icon.type === 'red'}"
        v-for="(icon, i) in isMobile ? iconsMobile : iconsPc"
        :style="{
         top: `${icon.y}px`,
         left: `${(isMobile ? startX : 0) + icon.x}px`,
         width: `${icon.hasOwnProperty('width') ? icon.width + 'px' : 'auto'}`
       }"
        :key="i">
        <img :src="icon.src" alt="icon">
      </div>
    </div>
    <template v-if="!isMobile">
      <div class="preview_layers preview_layers-left">
        <div
          class="preview_layers_layer"
          v-for="(layer, i) in layersLeft"
          :id="layer.type"
          :ref="layer.type"
          :style="{
         top: `${layer.y}px`,
         left: `${(layer.anim ? 0 : startX) + layer.x}px`,
         width: `${layer.width}px`
       }"
          :key="i">
          <img :src="layer.src" alt="icon">
        </div>
      </div>
      <div class="preview_layers preview_layers-right">
        <div
          class="preview_layers_layer"
          v-for="(layer, i) in layersRight"
          :id="layer.type"
          :ref="layer.type"
          :style="{
         top: `${layer.y}px`,
         left: `${layer.x}px`,
         width: `${layer.width || 'auto'}px`
       }"
          :key="i">
          <img :src="layer.src" alt="icon">
        </div>
        <div class="preview_line-anim">
          <video class="preview_line-anim_video"
                 muted
                 playsinline
                 preload="metadata"
                 ref="lineVideo"
          >
            <source src="../../assets/video/Line_with_BG2.mp4" type="video/mp4">
          </video>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="preview_layers">
        <div
          class="preview_layers_layer"
          v-for="(layer, i) in layersMobile"
          :id="layer.type"
          :ref="layer.type"
          :style="{
         top: `${layer.y}px`,
         left: `${startX + layer.x}px`,
         width: `${layer.width || 'auto'}px`
       }"
          :key="i">
          <img :src="layer.src" alt="icon">
        </div>
      </div>
    </template>
    <div class="preview_caption">
      <img src="../../assets/img/preview/preview_caption.png" alt="caption">
    </div>
  </div>
</template>

<script>
import { computed, onMounted, reactive, ref, toRefs } from 'vue'
import { gsap } from 'gsap'

import { useStore } from 'vuex'

export default {
  name: 'PreviewBlock',
  setup () {
    const lineVideo = ref(null)
    const windowWidth = ref(window.innerWidth)
    const store = useStore()
    const isMobile = computed(() => store.state.isMobile)
    const containerWidth = ref(isMobile.value ? 320 : 1280)
    const startX = ref((windowWidth.value - containerWidth.value) / 2)
    const items = reactive({
      iconsPc: [
        {
          type: 'black',
          src: require('../../assets/img/preview/icons/black/preview_icon1.png'),
          y: 80,
          width: 141,
          x: -141 - startX.value - 380,
          endX: 0
        },
        {
          type: 'black',
          src: require('../../assets/img/preview/icons/black/preview_icon2.png'),
          y: 170,
          width: 126,
          x: 100 - 126 - startX.value - 380,
          endX: 100
        },
        {
          type: 'black',
          src: require('../../assets/img/preview/icons/black/preview_icon3.png'),
          y: 280,
          x: 80 - 125 - startX.value - 380,
          width: 125,
          endX: 80
        },
        {
          type: 'black',
          src: require('../../assets/img/preview/icons/black/preview_icon4.png'),
          y: 80,
          x: 190 - 139 - startX.value - 380,
          width: 139,
          endX: 190
        },
        {
          type: 'black',
          src: require('../../assets/img/preview/icons/black/preview_icon5.png'),
          y: 0,
          x: 400 - 140 - startX.value - 380,
          width: 140,
          endX: 400
        },
        {
          type: 'black',
          src: require('../../assets/img/preview/icons/black/preview_icon6.png'),
          y: 300,
          x: 400 - 155 - startX.value - 380,
          width: 155,
          endX: 400
        }
      ],
      iconsMobile: [
        {
          type: 'black',
          src: require('../../assets/img/preview/icons/black/mobile/preview_icon1.png'),
          y: 70,
          width: 110,
          x: 0
        },
        {
          type: 'black',
          src: require('../../assets/img/preview/icons/black/mobile/preview_icon2.png'),
          y: 30,
          x: 210,
          width: 106
        },
        {
          type: 'black',
          src: require('../../assets/img/preview/icons/black/mobile/preview_icon3.png'),
          y: 200,
          x: 0,
          width: 96
        },
        {
          type: 'black',
          src: require('../../assets/img/preview/icons/black/mobile/preview_icon4.png'),
          y: 160,
          x: 210,
          width: 103
        },
        {
          type: 'black',
          src: require('../../assets/img/preview/icons/black/mobile/preview_icon5.png'),
          y: 280,
          x: 0,
          width: 97
        },
        {
          type: 'black',
          src: require('../../assets/img/preview/icons/black/mobile/preview_icon6.png'),
          y: 280,
          x: 210,
          width: 111
        },
        {
          type: 'red',
          src: require('../../assets/img/preview/icons/red/mobile/preview_icon7.png'),
          y: 500,
          x: 0,
          width: 111
        },
        {
          type: 'red',
          src: require('../../assets/img/preview/icons/red/mobile/preview_icon8.png'),
          y: 500,
          x: 210,
          width: 111.18
        },
        {
          type: 'red',
          src: require('../../assets/img/preview/icons/red/mobile/preview_icon9.png'),
          y: 650,
          x: 0,
          width: 110
        },
        {
          type: 'red',
          src: require('../../assets/img/preview/icons/red/mobile/preview_icon10.png'),
          y: 610,
          x: 210,
          width: 110
        }
      ],
      layersLeft: [

        {
          type: 'titleBaseLeft',
          src: require('../../assets/img/preview/layers/title_base_left.png'),
          y: 0,
          x: 135,
          width: 500.79,
          anim: false
        },
        /* {
          type: 'titleFilled',
          src: require('../../assets/img/preview/layers/title_filled.png'),
          width: 929,
          y: 20,
          x:240
        }, */

        {
          type: 'personFull',
          src: require('../../assets/img/preview/layers/person-full.png'),
          width: 929,
          y: 15,
          x: (929 + startX.value) * -1,
          endX: 165,
          anim: true
        }

      ],
      layersRight: [

        {
          type: 'titleBaseRight',
          src: require('../../assets/img/preview/layers/title_base_right.png'),
          y: 0,
          x: 0,
          width: 500.79
        },
        /* {
          type: 'titleFilled',
          src: require('../../assets/img/preview/layers/title_filled.png'),
          width: 929,
          y: 20,
          x:240
        }, */
        {
          type: 'personRed',
          src: require('../../assets/img/preview/layers/person-red.png'),
          width: 929,
          y: 20,
          x: -820,
          endX: -455
        },
        {
          type: 'portal-right',
          src: require('../../assets/img/preview/layers/portal-right.png'),
          y: 0,
          x: -498,
          width: 1002
        },
        {
          type: 'redIcon1',
          src: require('../../assets/img/preview/icons/red/preview_icon7.png'),
          y: 220,
          endY: 330,
          x: 750 - 900,
          endX: 120,
          width: 138
        },
        {
          type: 'redIcon2',
          src: require('../../assets/img/preview/icons/red/preview_icon8.png'),
          y: 220,
          endY: 0,
          x: 760 - 900,
          endX: 130,
          width: 122
        },
        {
          type: 'redIcon3',
          src: require('../../assets/img/preview/icons/red/preview_icon9.png'),
          y: 220,
          endY: 150,
          endX: 250,
          width: 118,
          x: 870 - 1000
        },
        {
          type: 'redIcon4',
          src: require('../../assets/img/preview/icons/red/preview_icon10.png'),
          y: 220,
          endY: 160,
          x: 1000 - 1141,
          endX: 450,
          width: 141
        }

      ],
      layersMobile: [
        {
          type: 'jump',
          src: require('../../assets/img/preview/layers/mobile/jump_logo_mobile.png'),
          y: 20,
          x: 110,
          width: 112
        },
        {
          type: 'arrow',
          src: require('../../assets/img/preview/layers/mobile/arrow_mobile.png'),
          toY: 170,
          baseY: 155,
          y: 155,
          x: 130,
          width: 64
        },
        {
          type: 'person',
          src: require('../../assets/img/preview/layers/mobile/person_mobile.png'),
          y: 210,
          x: 70,
          width: 200
        },
        {
          type: 'decoration',
          src: require('../../assets/img/preview/layers/preview_abstraction.png'),
          y: 655,
          x: 110,
          width: 274
        }
      ]
    })

    const startLineAnim = ref(false)

    const animateLeftPerson = () => {
      const personLeft = items.layersLeft.find(x => x.type === 'personFull')
      gsap.to(personLeft, {
        x: startX.value + personLeft.endX,
        duration: 1.5,
        ease: 'none'
      })
    }
    const animateRightPerson = () => {
      const personRight = items.layersRight.find(x => x.type === 'personRed')
      gsap.to(personRight, {
        x: personRight.endX,
        duration: 0.3,
        delay: 1.22,
        ease: 'none',
        onComplete: () => {
          if (lineVideo.value) {
            lineVideo.value.play()
            lineVideo.value.style.opacity = '1'
            startLineAnim.value = true
          }
        }
      })
    }
    const animateBlackIcons = () => {
      // const iconsBlack = items.iconsPc.filter(x => x.type === 'black')
      gsap.to('.preview_icons_icon--black', {
        x: (startX.value * 2) + 500,
        delay: 0.5,
        duration: 1
      })
    }
    const animateRedIcons = () => {
      const redIcon1 = items.layersRight.find(x => x.type === 'redIcon1')
      const redIcon2 = items.layersRight.find(x => x.type === 'redIcon2')
      const redIcon3 = items.layersRight.find(x => x.type === 'redIcon3')
      const redIcon4 = items.layersRight.find(x => x.type === 'redIcon4')
      gsap.to(redIcon1, {
        x: redIcon1.endX,
        y: redIcon1.endY,
        delay: 1.22,
        duration: 1
      })
      gsap.to(redIcon2, {
        x: redIcon2.endX,
        y: redIcon2.endY,
        delay: 1.22,
        duration: 1
      })
      gsap.to(redIcon3, {
        x: redIcon3.endX,
        y: redIcon3.endY,
        delay: 1.22,
        duration: 1
      })
      gsap.to(redIcon4, {
        x: redIcon4.endX,
        y: redIcon4.endY,
        delay: 1.22,
        duration: 1
      })
    }
    onMounted(() => {
      if (!isMobile.value) {
        setTimeout(() => {
          animateLeftPerson()
          animateBlackIcons()
          animateRightPerson()
          animateRedIcons()
        }, 1000)
      }
    })

    const arrow = items.layersMobile.find(x => {
      return x.type === 'arrow'
    })

    // animation arrow on mobile
    setInterval(() => {
      if (arrow.toY > arrow.y) {
        arrow.y += 1
      } else if (arrow.toY === arrow.y) {
        if (arrow.toY > arrow.baseY) arrow.toY -= 20
        else arrow.toY += 20
      } else if (arrow.toY < arrow.y) arrow.y -= 1
    }, 50)

    window.addEventListener('resize', () => {
      windowWidth.value = window.innerWidth
      containerWidth.value = isMobile.value ? 320 : 1280
      startX.value = (windowWidth.value - containerWidth.value) / 2
    })

    return {
      store,
      isMobile,
      containerWidth,
      startX,
      startLineAnim,
      lineVideo,
      ...toRefs(items)
    }
  }
}
</script>

<style lang="scss" scoped>
.preview {
  position: relative;
  user-select: none;
  width: 100%;
  min-height: 700px;

  @include mobile {
    min-height: 956px;
  }

  img {
    width: 100%;
  }

  &_icons {
    &_icon {
      z-index: 5;
      position: absolute;
    }
  }

  &_layers {
    display: flex;
    position: absolute;
    width: 50%;
    height: 700px;
    overflow-x: hidden;

    @include mobile {
      position: static;
      height: 600px;
      width: 100%;
    }

    &-left {

    }

    &-right {
      @include desktop {
        width: 50%;
        left: 50%;
      }
    }

    &_layer {
      z-index: 4;
      position: absolute;
    }
  }

  &_line-anim {
    position: absolute;
    top: 260px;
    left: 245px;

    &_video {
      opacity: 0;
      width: 505px;
    }
  }

  &_caption {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 652px;

    img {
      width: 100%;
    }

    @include mobile {
      width: 244px;
    }
  }
}
</style>
