<template>
  <div class="numbers" id="numbers">
    <div class="__block-title numbers_title">
      NUMBERS
    </div>
    <div class="numbers_container">
      <div class="numbers__number numbers__number--reach">
        <div class="numbers__number_title">997 000 000+</div>
        <div class="numbers__number_content">
          <img src="../../assets/img/numbers/reach.png" alt="reach">
        </div>
      </div>
      <div class="numbers__number numbers__number--years">
        <div class="numbers__number_title">
          <img src="../../assets/img/numbers/illustration_1.png" alt="illustration">
          9
          <img src="../../assets/img/numbers/illustration_2.png" alt="illustration">
        </div>
        <div class="numbers__number_content">
          <img src="../../assets/img/numbers/years.png" alt="years">
        </div>
      </div>
      <div class="numbers__number numbers__number--clicks">
        <div class="numbers__number_title">5 900 000+</div>
        <div class="numbers__number_content">
          <img src="../../assets/img/numbers/clicks.png" alt="clicks">
        </div>
      </div>
      <div class="numbers__number numbers__number--video">
        <div class="numbers__number_title">316 000 000+</div>
        <div class="numbers__number_content">
          <img src="../../assets/img/numbers/video.png" alt="video">
        </div>
      </div>
      <div class="numbers__number numbers__number--projects">
        <div class="numbers__number_title">507+</div>
        <div class="numbers__number_content"><img src="../../assets/img/numbers/projects.png" alt="projects"></div>
      </div>
      <div class="numbers__number numbers__number--engagements">
        <div class="numbers__number_title">20 136 000+</div>
        <div class="numbers__number_content">
          <img src="../../assets/img/numbers/engagement.png" alt="engagement">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NumbersBlock'
}
</script>

<style lang="scss" scoped>
.numbers {
  width: $desktop;
  margin-top: 250px;

  @include mobile {
    margin-top: 33px;
    width: 320px;
  }

  &_container {
    margin-top: 100px;
    display: grid;
    justify-content: center;
    justify-items: center;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 84px;

    @include mobile {
      margin-top: 40px;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 28px;
    }
  }

  &__number {
    display: flex;
    flex-direction: column;
    align-items: center;

    &.numbers__number--years {
      img {
        width: 154px;
      }

      @include mobile {
        order: 1;
        img {
          width: 58px;
        }
      }
    }

    &.numbers__number--projects {
      img {
        width: 206px;
      }

      @include mobile {
        order: 2;
        img {
          width: 77px;
        }
      }
    }

    &.numbers__number--video {
      img {
        width: 274px;
      }

      @include mobile {
        order: 4;
        img {
          width: 101px;
        }
      }
    }

    &.numbers__number--reach {
      img {
        width: 183px;
      }
      @include mobile {
        order: 3;

        img {
          width: 68px;
        }
      }
    }

    &.numbers__number--clicks {
      img {
        width: 183px;
      }

      @include mobile {
        order: 5;

        img {
          width: 77px;
        }
      }
    }

    &.numbers__number--engagements {
      img {
        width: 287px;
      }
      @include mobile {
        order: 6;

        img {
          width: 133px;
        }
      }
    }

    &_title {
      font-size: 30px;
      line-height: 130%;
      font-weight: 700;
      text-transform: uppercase;
      img {
        width: 50px !important;
      }

      @include mobile {
        font-size: 14px;
        line-height: 130%;

        img {
          width: 23px !important;
        }
      }
    }

    &_content {
      margin-top: 5px;
    }
  }
}
</style>
